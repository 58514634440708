import React, { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import moment from 'moment-timezone';
import { useRouter } from 'next/router';
import {
  Link as MuiLink,
  Button,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
  Avatar,
  Box,
  Typography,
} from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { generateColor } from '@/utils/colors';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from './Logo';
import { deleteCookie } from 'cookies-next';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import CustomerSupportModal from '../../../components/LayoutTools/Header/CustomerSupportModal';
import ContactUsModal from '../../../components/LayoutTools/Header/ContactUsModal';
import ErrorBoundary from '@/components/Reusable/ErrorHandling/ErrorBoundary';
import LogOutIcon from '../../../public/images/Icon-Logout.svg';
import { useSession } from '@/utils/hooks';
import { Auth } from 'aws-amplify';
import TermsOfUseDialog from '@/components/PageSpecific/terms-of-use/TermsOfUseDialog';
import PrivacyPolicyDialog from '@/components/PageSpecific/privacy-policy/PrivacyPolicyDialog';
import HelpPanel from '@/components/LayoutTools/HelpPanel/HelpPanel';
import { useHelpPanelContext } from '@/utils/helpPanelContext';

function HeaderContent(props) {
  const { helpPanelRef } = useHelpPanelContext();

  const { isMobile, toggleMobileMenu } = props;
  const { user, isImpersonating } = useSession();
  const [customerSupportModalOpen, setCustomerSupportModalOpen] =
    useState(false);
  const [contactUSModalOpen, setContactUSModalOpen] = useState(false);
  const [openTOU, setOpenTOU] = useState(false);
  const [openPP, setOpenPP] = useState(false);

  const router = useRouter();

  const clearImpersonatedUser = () => {
    deleteCookie('userChanged');
    deleteCookie('impersonatedUser');

    router.reload();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const editAccount = () => {
    router.push(`/user/${encodeURIComponent(user.email)}`);
  };

  const changePasswordRedirect = () => {
    router.push('/change-password');
  };

  const goToHubReleases = () => {
    router.push('/hub-releases');
  };

  const logoutClick = async () => {
    await Auth.signOut();
    deleteCookie('impersonatedUser');
    router.push(`/login?callbackUrl=${router.asPath}`);
  };

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const openCustomerSupportModal = () => {
    closeMenu();
    setCustomerSupportModalOpen(true);
  };

  const openContactUSModal = () => {
    closeMenu();
    setContactUSModalOpen(true);
  };

  return (
    <>
      {isMobile ? (
        <Button onClick={toggleMobileMenu(true)}>
          <MenuIcon />
        </Button>
      ) : (
        <Link href='/hub' passHref legacyBehavior>
          <MuiLink>
            <Logo />
          </MuiLink>
        </Link>
      )}
      <Box sx={{ flex: 1 }} />
      <Box sx={{ display: 'flex', verticalAlign: 'middle', mx: 2 }}>
        <HelpPanel ref={helpPanelRef} />
      </Box>
      <Box sx={{ height: '100%' }}>
        <Button
          sx={{
            display: 'inline-flex',
            width: 'auto',
            alignItems: 'center',
            paddingRight: '1rem',
            height: '100%',
            '& > *': {
              margin: '8px',
            },
          }}
          onClick={handleOpenMenu}
          color='grey'
        >
          <Avatar style={{ backgroundColor: generateColor(user?.lastName) }}>
            {`${user?.firstName?.[0]}${user?.lastName?.[0]}`}
          </Avatar>
          <Typography variant='caption' sx={{ margin: 1 }}>
            {isImpersonating && 'Impersonating '}
          </Typography>
          <Typography sx={{ margin: 0 }}>
            {user?.firstName} {user?.lastName}
          </Typography>
          <KeyboardArrowDown />
        </Button>
        <Menu
          id='account-menu'
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={closeMenu}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          {isImpersonating && (
            <MenuItem onClick={() => clearImpersonatedUser()}>
              Stop Impersonating User
            </MenuItem>
          )}
          {/* TODO: Should we make this typography a pattern to use elsewhere? */}
          <Typography
            variant='h6'
            sx={{
              margin: 1,
              marginBottom: 0,
              color: 'grey.lighter',
              // fontSize: '0.7rem',
            }}
          >
            Personal Settings
          </Typography>
          <MenuItem onClick={editAccount}>My profile</MenuItem>
          <MenuItem onClick={changePasswordRedirect}>Change password</MenuItem>
          <Divider />
          <MenuItem onClick={goToHubReleases}>Release notes</MenuItem>
          <Divider />
          <MenuItem onClick={logoutClick}>
            <ListItemIcon>
              <Image src={LogOutIcon} alt='Log Out' />
            </ListItemIcon>
            <ListItemText>Log out</ListItemText>
          </MenuItem>
          <Divider />
          <Box
            sx={{
              width: '100%',
              margin: '1rem auto',
              textAlign: 'center',
            }}
          >
            <Button
              variant='contained'
              color='success'
              startIcon={<ContactSupportOutlinedIcon />}
              onClick={() => openCustomerSupportModal()}
            >
              Give us feedback
            </Button>
          </Box>
          <Box
            sx={{
              width: '100%',
              margin: '1rem auto',
              textAlign: 'center',
            }}
          >
            <Button
              onClick={() => openContactUSModal()}
              sx={{
                textTransform: 'none',
                fontSize: '15px',
                color: 'black',
              }}
            >
              Contact us
            </Button>
            <Button
              onClick={() => setOpenTOU(true)}
              sx={{
                textTransform: 'none',
                fontSize: '15px',
                color: 'black',
              }}
            >
              Terms of use
            </Button>
            <Button
              onClick={() => setOpenPP(true)}
              sx={{
                textTransform: 'none',
                fontSize: '15px',
                color: 'black',
              }}
            >
              Privacy policy
            </Button>
          </Box>
          <Typography variant='caption' m={2}>
            Version: {process.env.NEXT_PUBLIC_VERSION}
          </Typography>
          <Typography
            sx={{
              margin: 2,
              fontSize: '11px',
            }}
          >
            © {moment().year()} Cornerstone Information Systems, Inc.
          </Typography>
        </Menu>
      </Box>
      <ErrorBoundary>
        <CustomerSupportModal
          isOpen={customerSupportModalOpen}
          onClose={() => setCustomerSupportModalOpen(false)}
        />
        <ContactUsModal
          isOpen={contactUSModalOpen}
          onClose={() => setContactUSModalOpen(false)}
        />
      </ErrorBoundary>
      <TermsOfUseDialog open={openTOU} setOpen={setOpenTOU} />
      <PrivacyPolicyDialog open={openPP} setOpen={setOpenPP} />
    </>
  );
}

export default HeaderContent;
